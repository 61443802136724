var _a, _b;
import { Scope } from '../shared/types/db_schema/experimentConfig';
export var A_DAY = 86400000;
export var HOLDOUT_TREATMENT_NAMES = {
    CONTROL_VARIANT: '_HOLDOUT',
    EXPERIMENT_VARIANT: '_EXPERIMENT_ELIGIBLE',
};
export var TOOLTIP_COPY = {
    CONFIDENCE_INTERVAL: 'The center line on the chart represents 0% difference from the control. The purple line, known as the Point Estimate, shows the % difference between the treatment and the control. The shaded regions represent confidence intervals (CI) p90 (innermost), p95, p99 (outermost). Both bounds for a given CI must be above or below 0% for a test to be considered statistically significant at that confidence level.',
    VALUE_CONTROL: 'Refers to the mean value for a given metric in the control.',
    VALUE_TREATMENT: 'Refers to the mean value for a given metric in the currently selected treatment.',
    P_VALUE: 'Indicates the confidence we have in the results we’re seeing. As it approaches zero or one, we become more confident in rejecting the “null hypothesis” that there is no difference between experimental groups. We consider ourselves “statistically significant and successful” if the number falls below the desired “Fault Tolerance”, which is set during configuration of the experiment.',
    PERCENT_CHANGE: 'Shows how the treatment performance has changed over time relative to the control.',
    // EXPERIMENT_RUNNING_STATUS is not here--we define that as a ReactElement in-line in the component to define line-breaks
    EXPERIMENT_DECISION_STATUS: 'The result of an experiment. Set this after an experiment is COMPLETE.',
    EXPERIMENT_TAGS: 'Tags are used to create a mutually exclusive set of experiments per Scope.',
    EXPERIMENT_NAME: 'The name and unique identifier for this experiment. Not editable after creation.',
    EXPERIMENT_SCOPE_PERCENTAGE: 'Percent of overall traffic being allocated to a particular experiment. If an experiment running status is LIVE, this number must be between 1-100%.',
    EXPERIMENT_VARIANT_NAME: 'The name and unique identifier for this experiment variant. Not editable after creation.',
    EXPERIMENT_VARIANT_SAMPLE_SIZE: 'The minimum number of users per variant calculated during pre-analysis. If you are unsure about what this value should be, please consult with the Analytics team.',
    HOLDOUT_NAME: 'The name and unique identifier for this experiment. Automatically generated for holdouts and not editable.',
    HOLDOUT_VARIANT_TRAFFIC_PERCENTAGE: "Automatically calculated as 100 minus the Control Variant's Traffic Percentage.",
};
export var TR_CUBIC = 'cubic-bezier(0.36,0.07,0.19,0.97)';
export var HOME_PAGE_ERROR_MSG = 'An error occurred. Please make sure you have 10gen-pholiota-read and 10gen-pholiota-write MANA permissions. If so, try refreshing the page.';
export var CONFIG_ERROR_MSG = {
    TAG: '"Tag" field must be defined',
    TEST_NAME: '"Test Name" field must be defined"',
    SCOPE_PERCENTAGE: '"Scope Percentage" field must be defined',
    SCOPE_PERCENTAGE_VALUE: 'Scope Percentage must be a percent value between 0 (or 1, if experiment is LIVE) and 100',
    PRIMARY_METRIC: '"Primary Metric" field must be defined',
    VARIANT_SAMPLE_SIZE: '"Variant Sample Size" field must be defined',
    JIRA_LINK: '"JIRA Link" field must be defined',
    TARGET_DURATION: '"Target Duration" field must be defined',
    VARIANT_NAMES: 'All variants must have a name',
    FEATURE_FLAGS_INCOMPLETE: 'All experiment attributes must have both a key and a value',
    FEATURE_FLAGS_DUPLICATE_KEYS: 'Variants cannot have experiment attributes with duplicate keys',
    DELETE_EXPERIMENT_ERROR_ADDENDUM: 'If you have just updated the experiment status, please submit that update first by hitting "Confirm" at the bottom of the form. Then try deleting the experiment again.',
};
export var DETAILS_ERROR_MSG = {
    FETCH_RESULTS_ERROR: 'Something went wrong. In case of a timeout error, try adjusting the timeframe to get a smaller set of results.',
    DATE_FORMAT_ERROR: 'Specified date is not in the correct format.',
    SCOPE_PERCENTAGE_ERROR: "Experiment can't go LIVE with a scopePercentage less than 1. Please edit the experiment configuration.",
};
export var DETAILS_RESULTS_MSG = {
    NO_RESULTS: 'No results for this experiment... yet!',
    NO_RESULTS_TIMEFRAME: 'There are no results for this timeframe.',
};
export var ALLOCATION_POINTS_ERROR_MSG = {
    READABLE_NAME: '"Readable Name" must be defined',
    ID: '"Id" must be defined',
    DESCRIPTION: '"Description" must be defined',
};
export var ENV_HOSTS;
(function (ENV_HOSTS) {
    ENV_HOSTS["LOCAL"] = "localhost";
    ENV_HOSTS["DEV"] = "pholiota-dev.corp.mongodb.com";
    ENV_HOSTS["QA"] = "pholiota-qa.corp.mongodb.com";
    ENV_HOSTS["PROD"] = "pholiota.corp.mongodb.com";
})(ENV_HOSTS || (ENV_HOSTS = {}));
export var ENVIRONMENT;
(function (ENVIRONMENT) {
    ENVIRONMENT["LOCAL"] = "local";
    ENVIRONMENT["DEV"] = "dev";
    ENVIRONMENT["QA"] = "qa";
    ENVIRONMENT["PROD"] = "prod";
})(ENVIRONMENT || (ENVIRONMENT = {}));
export var HOST_TO_ENV = (_a = {},
    _a[ENV_HOSTS.LOCAL] = ENVIRONMENT.LOCAL,
    _a[ENV_HOSTS.DEV] = ENVIRONMENT.DEV,
    _a[ENV_HOSTS.QA] = ENVIRONMENT.QA,
    _a[ENV_HOSTS.PROD] = ENVIRONMENT.PROD,
    _a);
export var DEFAULT_ENV = ENVIRONMENT.PROD;
export var REALM_DATA = (_b = {},
    _b[ENV_HOSTS.LOCAL] = {
        REALM_APP_ID: process.env.NEXT_PUBLIC_LOCAL_APP_ID || 'pholiota-ol-dev-pbape',
        REALM_BASE_URL: 'https://stitch-qa.mongodb.com',
    },
    _b[ENV_HOSTS.DEV] = {
        REALM_APP_ID: 'pholiota-ol-dev-pbape',
        REALM_BASE_URL: 'https://pholiota-dev.corp.mongodb.com',
    },
    _b[ENV_HOSTS.QA] = {
        REALM_APP_ID: 'pholiota-ol-qa-qeaue',
        REALM_BASE_URL: 'https://pholiota-qa.corp.mongodb.com',
    },
    _b[ENV_HOSTS.PROD] = {
        REALM_APP_ID: 'pholiota-ol-prod-khixd',
        REALM_BASE_URL: 'https://pholiota.corp.mongodb.com',
    },
    _b);
export var DEFAULT_METRICS = [
    'Cluster Conversion Rate (Overall)',
    'Cluster Conversion Rate (PT)',
    'Cluster Conversion Rate (FT)',
    'Cluster Creation Rate',
    'Free Cluster Creation Rate',
    'Paid Cluster Creation Rate',
    'Cluster Deletion Rate',
    'Paid Cluster Deletion Rate',
    'Avg. Cluster Created (Net, Overall)',
    'Avg. Cluster Created (Net, PT)',
    'Avg. Cluster Created (Net, FT)',
    'Setup Moment',
    'Aha Moment',
    'Activation Moment',
    'Usage Retention Rate (14-Day)',
    'Gross Revenue (Sum, Ex Credit)',
    'Negative MRR Change Above 50 Percent, 7-Day Window',
    'Positive MRR Change Above 10 Percent, 7-Day Window',
    'Cluster Creation Rate, 7 day window',
    'Free Cluster Creation Rate, 7 day window',
    'Paid Cluster Creation Rate, 7 day window',
    'Dedicated Tier Cluster Creation Rate',
    'Dedicated Cluster Creation or Upgrade Rate',
];
export var ITEMS_PER_PAGE = 25;
export var ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export var NON_ORG_SCOPES = Object.keys(Scope).filter(function (scope) { return scope !== Scope.ORG; });
